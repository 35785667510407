<template>
  <HeroImageOverlay class="">
    <div class="px-4 py-10 md:p-16 bg-white rounded-lg md:w-max">
      <h1>Ted Michaels</h1>
      <div class="w-24 h-1 mt-10 bg-accent-light"></div>
      <p class="max-w-md mt-6">
        Ted Michaels is a Community Champion, Health and Wellness Advocate, Public Speaker, Charity Fundraiser, Corus Radio Hamilton 2021 Hall of Fame Inductee and Broadcaster who is a strong advocate for mental health and wellness in his community.
      </p>
      <router-link to="/contact" class="btn btn-xl mt-6">Get In Touch</router-link>
    </div>
  </HeroImageOverlay>

  <!-- About Ted -->
  <Split
    title="About Ted"
    description="Former radio news anchor and talk show host, radio play-by-play voice of McMaster Marauders football and so much more..."
    :flip="true"
  >
    <template v-slot:extra-content>
      <router-link to="/about" class="btn btn-xl mt-8">Learn More</router-link>
    </template>
    <img
      src="@/assets/images/sit.jpg"
      alt="ted michaels sitting in the radio booth"
      class="w-full h-full object-cover"
    />
  </Split>

  <Carousel title="In The Community" class="bg-accent-lightest" />

  <!-- Services Offered -->
  <Container>
    <div class="text-center flex flex-col items-center mb-12">
      <h2 class="mb-0">Services Offered</h2>
      <div class="w-20 h-1 my-5 bg-accent-light"></div>
      <!-- <p class="max-w-2xl">
        Phasellus lorem quam molestie id quisque diam aenean nulla in. Accumsan in quis quis nunc,
        ullamcorper malesuada. Eleifend condimentum id viverra nulla.
      </p> -->
    </div>
    <div class="grid gap-10 lg:grid-cols-3">
      <div
        v-for="s in services"
        :key="s.title"
        class="
          bg-accent-lightest
          flex flex-col
          items-center
          text-center
          pt-16
          p-8
          rounded-lg
          relative
        "
      >
        <div
          class="
            w-16
            h-16
            bg-accent-light
            shadow-lg
            grid
            place-items-center
            rounded-lg
            absolute
            -top-8
          "
        >
          <span v-html="s.icon" />
        </div>
        <h3 class="">{{ s.title }}</h3>
        <p v-html="s.description"></p>
      </div>
    </div>
    <div class="grid place-items-center mt-12">
      <router-link to="/services" class="btn btn-xl">View all services</router-link>
    </div>
  </Container>

  <!-- Container -->
  <Container class="bg-accent-lightest">
    <div class="flex justify-between items-center">
      <h2>Video Content</h2>
      <div class="hidden md:block">
        <router-link to="/videos" class="btn btn-xl">All Videos</router-link>
      </div>
    </div>
    <div class="w-24 h-1 bg-accent-light"></div>
    <!-- <p class="mt-6">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ornare scelerisque eleifend
      faucibus lacus pharetra, arcu libero.
    </p> -->
    <div class="mt-6 grid gap-10 sm:grid-cols-2 lg:grid-cols-3">
      <div v-for="v in videos" :key="v.title" class="">
        <div class="rounded-lg object-cover relative overflow-hidden aspect-w-3 aspect-h-2 group cursor-pointer" v-on:click="
                                open = true;
                                youtubeId = v.id;
                              ">
          <img :src="'https://img.youtube.com/vi/' + v.id + '/hqdefault.jpg'" class="object-cover	transform" alt="holding a torch" />
          <div class="absolute inset-0 bg-black bg-opacity-50 transition group-hover:bg-opacity-40 grid place-items-center">
            <div>
              <img src="@/assets/play.svg" alt="play button" class="w-20 h-20" />
            </div>
          </div>
        </div>
        <h3 class="text-2xl mt-4">{{ v.title }} | {{ v.topic }}</h3>
      </div>
    </div>
  </Container>

  <!-- SimpleBrandCTA -->
  <SimpleBrandCTA />
  

<TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent" @click="open = false">
                <span class="sr-only">Close</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="sm:flex sm:items-start mt-8">
              <div class="text-center sm:mt-0 sm:text-left  w-full">
                <div class="aspect-w-16 aspect-h-9 w-full">
                  <iframe class="w-full h-full" :src="'https://www.youtube.com/embed/' + youtubeId + '?autoplay=true'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import Carousel from "../../components/layouts/features/Carousel.vue";
import HeroImageOverlay from "../../components/layouts/hero/HeroImageOverlay.vue";
import Header from "@/components/base/Header";
import Split from "@/components/layouts/Split.vue";
import Container from "@/components/layouts/Container.vue";
import SimpleBrandCTA from "@/components/layouts/cta/SimpleBrandCTA.vue";
import { SpeakerphoneIcon } from "@heroicons/vue/outline";
import { Dialog, DialogOverlay,  TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'

const services = [
  {
    title: "Speaking",
    description:
      "Keynote speaker about mental health and anxiety, how it affected me and what I did about it. Will also discuss how those mental health issues, planted the seed for an award-winning, health and wellness radio show.",
      icon: "<svg xmlns='http://www.w3.org/2000/svg' class='h-10 w-10' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'> <path stroke-linecap='round' stroke-linejoin='round' d='M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z' /> </svg>"
  },
  {
    title: "Marketing",
    description:
      "Will work with clients on how to market an event, taking it <strong>over and above</strong> the ordinary.",
      icon: "<svg xmlns='http://www.w3.org/2000/svg' class='h-10 w-10' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'> <path stroke-linecap='round' stroke-linejoin='round' d='M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z' /> </svg>"
  },
  {
    title: "Consulting",
    description:
      "Help businesses and charities get the biggest bang for the buck when it comes to news releases and how to avoid the mistakes that many people make.  <br/>Want to know how to get exposure?  I'll tell you how.",
    icon:"<svg xmlns='http://www.w3.org/2000/svg' class='h-10 w-10' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'> <path stroke-linecap='round' stroke-linejoin='round' d='M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z' /> </svg>"
  },
];

const videos = [
  {
    title: "CHML Chicago",
    topic: "Interview with Ted Michaels",
    id: "DJDKey-BxfQ",
  },
  {
    title: "Ted Michaels",
    topic: "Pan Am Torch Relay 2015",
    id: "p2NnfV96V3g",
  },
];

export default {
  components: {
    Header,
    Split,
    Container,
    SimpleBrandCTA,
    HeroImageOverlay,
    SpeakerphoneIcon,
    Carousel,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  setup() {
    const open = ref(false)
    const youtubeId = "";
    return {
      open,
      youtubeId
    }
  },
  data() {
    return {
      services,
      videos,
    };
  },
   methods: {
      openVideo(id){
        alert(id);
      }
    },
};
</script>

<template>
  <main class="relative md:min-h-screen">
    <div class="md:absolute inset-0 w-full h-full pt-12 md:pt-0">
      <img src="@/assets/images/hero.jpg" alt="" class=" w-full h-full object-cover" />
    </div>
    <div class="md:absolute inset-0 grid items-center border-b md:border-none">
      <Container class="no-padding">
        <slot></slot>
      </Container>
    </div>
  </main>
</template>

<script>
import Container from "@/components/layouts/Container";

export default {
  components: {
    Container,
  },
};
</script>
